<template>
  <v-container fluid>

    <v-toolbar rounded>
      <span class="text-h5">Messages d'accueil</span>

      <v-spacer></v-spacer>

      <homepage-message-form
          v-model="creatingMessage"
          :clientList="clientList"
          actionLabel="Créer"
          title="Création d'un message d'accueil"
          @save="saveMessage"
      >
        <template v-slot:activator="{attrs, on}">
          <v-btn v-on="on" v-bind:attrs="attrs" color="primary" small @click="reset">Créer</v-btn>
        </template>
      </homepage-message-form>
    </v-toolbar>

    <v-card class="mt-4">
      <template v-if="!loadingList && homepageMessageList.length == 0">
        <v-list-item class="ma-0 text-center">
          <v-list-item-title class="subtitle-1">Aucun message paramétré</v-list-item-title>
        </v-list-item>
      </template>
      <template v-if="loadingList">
        <div class="d-flex flex-column align-center justify-center pa-8">
          <v-progress-circular size="32" indeterminate color="primary"></v-progress-circular>
        </div>
      </template>
      <template v-if="homepageMessageList.length > 0">
        <v-list>
          <template v-for="(homepageMessage, i) in homepageMessageList">
          <v-list-item :key="'homepage-message-' + i">

              <template #default>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ homepageMessage.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ homepageMessage.list.length }} bases
                  </v-list-item-subtitle>
                </v-list-item-content>

                <div class="d-flex flex-row align-center">
                  <v-switch v-model="homepageMessage.active" class="mr-12" label="Actif" @change="confirmActive = i"></v-switch>

                  <homepage-message-form
                      v-model="creatingMessage"
                      :clientList="clientList"
                      actionLabel="Editer"
                      actionColor="warning"
                      title="Edition d'un message d'accueil"
                      @save="saveMessage(creatingMessage, i)"
                  >
                    <template v-slot:activator="{attrs, on}">
                      <v-btn v-on="on" :attrs="attrs" icon @click="editMessage(i)">
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                  </homepage-message-form>

                  <v-btn icon>
                    <v-icon @click="confirmDelete = i">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>

            </v-list-item>

            <v-divider :key="'homepage-message-separator-' + i"></v-divider>
          </template>

        </v-list>
      </template>
    </v-card>

    <v-dialog :value="confirmDelete !== null" width="25%">
      <v-card>
        <v-toolbar color="danger" dense>
          <v-toolbar-title>Confirmation de suppression</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          <span>Êtes-vous sûr(e) de vouloir supprimer ce message ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDelete = null">Annuler</v-btn>
          <v-btn color="error" :loading="loadingDelete" @click="deleteMessage">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="confirmActive !== null" :value="confirmActive !== null" width="25%" @click:outside="cancelActivation">
      <v-card>
        <v-toolbar color="danger" dense>
          <v-toolbar-title>Confirmation d'activation</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          <span>Êtes-vous sûr(e) de vouloir {{ homepageMessageList[confirmActive].active ? "" : "dés"}}activer ce message ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelActivation">Annuler</v-btn>
          <v-btn color="warning" :loading="loadingDelete" @click="toggleActive">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import { SVSHomepage } from "@/Services/SupportVisionService";

export default {
  name: "HomepageMessage",
  components: {
    HomepageMessageForm: () => import("@/Components/Views/SupportVision/HomepageMessage/Form")
  },
  data() {
    return {
      clientList: [],
      loadingList: false,
      homepageMessageList: [],
      creatingMessage: {},
      editing: null,
      confirmDelete: null,
      confirmActive: null,
      loadingDelete: false
    }
  },
  methods: {
    reset() {
      this.editing = null
      this.creatingMessage = {
        title: "",
        list: [],
        active: false,
        message: "",
        color: null,
        icon: null,
        start: null,
        end: null
      }
    },
    async saveMessage(message, index) {
      let response
      if (this.editing === null) {
        response = await SVSHomepage.postHomepageMessage(message)
        this.homepageMessageList.push(response)
      } else {
        response = await SVSHomepage.putHomepageMessage(message)
        this.reset()
        this.$set(this.homepageMessageList, index, response.data)
      }
      if (response) {
        this.$nSuccess("Message d'accueil " + (this.editing === null ? "créé" : "modifié"))
      } else {
        this.$nError("Impossible de " + (this.editing === null ? "créer" : "modifier") + " le message d'accueil")
      }
    },
    async editMessage(index) {
      this.editing = index
      this.creatingMessage = JSON.parse(JSON.stringify(this.homepageMessageList[index]))
    },
    async deleteMessage() {
      let index = this.confirmDelete
      let _id = this.homepageMessageList[index]._id
      this.loadingDelete = true
      let deleted = await SVSHomepage.removeHomepageMessage(_id)
      if (deleted) {
        let index = this.homepageMessageList.findIndex(a => a._id == _id)
        this.homepageMessageList.splice(index, 1)
        this.$nSuccess("Message supprimé")
      } else {
        this.$nError("Message non supprimé")
      }
      this.confirmDelete = null
      this.loadingDelete = false

    },
    async toggleActive() {
      let index = this.confirmActive
      let toEdit = this.homepageMessageList[index]
      let response = await SVSHomepage.putHomepageMessage(toEdit)
      this.confirmActive = null
    },
    cancelActivation() {
      this.homepageMessageList[this.confirmActive].active = !this.homepageMessageList[this.confirmActive].active
      this.confirmActive = null
    }
  },
  async mounted() {

    this.loadingList = true
    this.clientList = await PlaneteOnlineService.getBases(true)
    this.homepageMessageList = await SVSHomepage.getHomepageMessageList()
    this.loadingList = false
  }
}
</script>

<style scoped>

</style>